.main {
    font-size: 1.5rem;
    margin: 5rem 0;
    line-height: 2.25rem;
}

.title {
    font-size: 2rem;
    color: #379B4B;
    font-weight: 600;
}

.video {
    width: 100%;
}