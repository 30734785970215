.main {
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.question {
    cursor: pointer;
    transition: all .25s;
    background-color: #E0DADA;
    margin: 1rem;
    width: 100%;
    padding: 1rem;
    text-align: center;
}

.question:hover {
    background-color: green;
    color: white;
}

.answer {
    transition: max-height .5s ease-in-out;
    overflow: hidden;
}