.title {
    font-size: 5rem;
    text-align: center;
    margin: 5rem;
    line-height: 5rem;
    margin-top: 3rem;
}

.article {
    font-size: 4rem;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header img {
    width: 13rem;
    margin: 2rem;
    cursor: pointer;
}

.body {
    display: flex;
}

.content {
    background-color: #F6F6FC;
    flex:3;
    padding: 6.5vw;
    padding-top: 2%;
}

.navbar, .rightBar {
    flex:1;
    padding: 2rem;
}

@media (max-width: 1200px) {
    .navbar, .rightBar {
        display: none !important;
    }
}

.navbar {
    display: flex;
    flex-direction: column;
    position: sticky;
    top:0;
    height: 100vh;
    overflow-x: auto;
}

.numberedListInnerDiv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.numberedListImage {
    margin: 0 .5rem;
    height: 100%;
}

.categoryLink, .subCategoryLink, .articleLink {
    font-size: 1.8rem;
    color: black;
    font-weight: 600;
    text-decoration: none;
    margin: 1rem;
}

.articleLink {
    font-weight: normal;
    font-size: 1.6rem;
    margin: .5rem 4rem;
}

.subCategoryLink {
    color: green;
    margin: 0 2rem;
}

.progressBar {
    background:lightgray;
    width: 3px;
    position: absolute;
    height: 100vh;
}

.progressBarContent {
    background:#379B4B;
    width: 3px;
    position: absolute;
}


