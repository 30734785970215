.content {
    background-color: #f6f6fc;
    padding: 2rem 6vw;
    margin: 0 20vw;
    display: flex;
    flex-direction: column;
    line-height: 1.42857143;
}

.title {
    font-size: 3rem;
    border-left: 1rem #33a053 solid;
    padding-left: 1rem;
    font-weight: 700;
}

.bulletPoint {
    font-size: 1.7rem;
    margin-bottom: 1rem;
    list-style: none;
    position: relative;
}

.bulletPoint::before {
    background: #33a053;
    border-radius: 8px;
    height: 12px;
    width: 12px;
    content: "";
    top: 6px;
    left: -16px;
    position: absolute;
}

.video {
    padding-bottom: 7rem;
    margin-bottom: 5rem;
    border-bottom: 1rem #33a053 solid;
}
