.title {
    font-size: 5rem;
    text-align: center;
    margin: 5rem;
}

.article {
    font-size: 4rem;
}

.body {
    background-color: #F6F6FC;
    padding: 120px;
    padding-top: 2%;
    margin: 0 20%;
}

.numberedListInnerDiv {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
}

.numberedListImage {
    margin: 0 .5rem;
    height: 100%;
}