.title {
    font-size: 5rem;
    text-align: center;
    margin: 5rem;
    line-height: 5rem;
    margin-top: 3rem;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header img {
    width: 13rem;
    margin: 2rem;
    cursor: pointer;
}

.body {
    display: flex;
    flex-direction: column;
}

.section {
    display: flex;
    margin: 3vh 6vw;
    flex-direction: row;
    flex-wrap: wrap;
}

.subtitle {
    font-size: 3rem;
    margin: 6vh 0 2vh 7.5vw;
    border-left: 1rem #33a053 solid;
    padding-left: 1rem;
}
