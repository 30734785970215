.button {
    border: none;
    border-radius: 12px;
    padding: 4rem 0;
    background: white;
    font-weight: 600;
    color: #707070;
    filter: drop-shadow(2px 5px 12px rgba(0, 0, 0, 0.078));
    cursor: pointer;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 30rem;
    width: 28%;
    position: relative;
}

.buttonImage {
    height: 8rem;
    width: 8rem;
    margin-bottom: 1rem;
}

.buttonMainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonTitle {
    font-size: 2.2rem;
    margin-bottom: .5rem;
}

.buttonDescription {
    font-style: italic;
}

.unavailable {
    position: absolute;
    color: white;
    display: flex;
    background: rgba(0,0,0, 0.55);
    height: 100%;
    width: 100%;
    top:0;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    border-radius: 12px;
    cursor: default;
}