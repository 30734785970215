.container {
    position: relative;
}

.inputContainer {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 2rem;
    background-color: white;
    border: none;
    width: 100%;
    position: relative;
    margin-right: 1rem;
}

.input {
    background-color: transparent;
    font-size: 1.5rem;
    border: none;
    width: 100%;
}

.input:focus-within {
    outline: none;
}

.inputImage {
    height: 16px;
    margin-right: 0.5rem;
}

.searchResults {
    padding: 1rem 0.5rem;
    width: 100%;
    position: absolute;
    font-size: 1.5rem;
    background-color: white;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.searchResult {
    padding: 0.5rem;
    display: flex;
    cursor: pointer;
    font-weight: normal;
    transition: all 0.15s;
    text-align: initial;
}

.searchResult:hover {
    background-color: #f0f0f0;
}
