.title {
    font-size: 5rem;
    text-align: center;
    margin: 5rem;
    line-height: 5rem;
    margin-top: 3rem;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header img {
    width: 13rem;
    margin: 2rem;
    cursor: pointer;
}
