.content {
    background-color: #f6f6fc;
    margin: 0 20vw;
    padding: 2rem 6vw;
}

.title {
    font-size: 4rem;
}

.paragraph {
    font-size: 1.7rem;
    margin: 2rem 0;
}
