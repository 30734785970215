.main {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title {
    font-size: 4rem;
}

.content {
    background-color: #f6f6fc;
    padding: 2rem 6vw;
    margin: 0 20vw;
    display: flex;
    flex-direction: column;
    line-height: 1.42857143;
}

.logo {
    width: 8rem;
    align-self: center;
    margin: 3rem 0;
}

.question {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 0;
}

.answer {
    margin-top: 0.5rem;
    font-size: 1.8rem;
}
