.main {
    background-color: #EEFFEF;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
}

.title {
    background-color: #329846;
    width: fit-content;
    color: white;
    padding: 5px 10px;
    position: relative;
    font-size: 1.7rem;
    
}

.content {
    margin: 0 2rem;
}