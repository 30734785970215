.list {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:1.5rem 0;
}

.number {
    background-color:  #329846;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 32px;
    min-width: 32px;
    margin: 1.5rem;
}

.children {

}