.header {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    color: black;
    display: flex;
    min-height: 30rem;
    background-color: #b0f2b6;
    filter: drop-shadow(2px 5px 12px rgba(0, 0, 0, 0.078));
    position: relative;
    z-index: 10;
}

.headerCenter {
    display: flex;
    flex-direction: column;
    flex: 4;
    align-items: center;
    justify-content: center;
}

.headerLeftContainer,
.headerRightContainer {
    display: flex;
    flex: 1;
}

.headerRightContainer {
    justify-content: flex-end;
}

.leftImage {
    object-fit: contain;
    width: auto;
    max-width: 27vw;
}

.mainContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 10%;
}

.taskoyaLogo {
    width: 13rem;
}

.title {
    margin: 2rem 0 1rem 0;
    font-size: 5rem;
    font-weight: 600;
}
