.container {
    display: flex;
    background-color: white;
    filter: drop-shadow(2px 5px 12px rgba(0, 0, 0, 0.28));
    border: 1px solid #33a053;
    border-radius: 0.8rem;
    align-items: center;
    flex-direction: column;
    width: 30%;
    margin: 1vh 1.37vw;
    min-width: 42rem;
    min-height: 35rem;
    overflow: hidden;
}

.imageContainer {
    height: 50%;
    position: relative;
    overflow: hidden;
    transform: scale(1);
    background: linear-gradient(0deg, rgba(51, 160, 83, 1) 77%, rgba(28, 103, 50, 1) 100%);
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.container:hover .image {
    transform: scale(1.2);
}

.title {
    font-size: 2rem;
    color: white;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 1rem;
    z-index: 1;
    text-align: center;
    font-weight: 600;
    line-height: 3rem;
}

.category {
    text-align: left;
    border: solid black 1px;
    border-radius: 15px;
    width: fit-content;
    padding: 0 1rem;
    font-size: small;
    color: grey;
}

.button {
    background-color: #33a053;
    border-radius: 15px;
    color: white;
    border: none;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: all 0.25s;
}

.button:hover {
    background-color: #25743c;
}

.description {
    font-size: 1.3rem;
    text-align: center;
    flex: 1;
}

.image {
    width: 100%;
    transition: all 0.5s;
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1rem;
}

.companyHeader {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.companyName {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.75rem;
}
